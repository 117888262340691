import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
    return (
        <div className='hero-container'>
            <h1>DESIGN. DEVELOP. DREAM.</h1>
            <p>EXPLORING AI, SOFTWARE ENGINEERING, AND GAME DEVELOPMENT BY TACKLING UNIQUE SITUATIONS WITH UNIQUE SOLUTIONS</p>
            <div className="hero-btns">
                {/* <Button 
                    className='btns' 
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >
                    Get Started
                </Button>
                <Button 
                    className='btns' 
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                >
                    Watch Trailer <i className='far fa-play-circle' />
                </Button> */}
            </div>
        </div>
    )
}

export default HeroSection
