import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

function Home () {
    return(
        <>
        <HeroSection />
        </>

    );
}

export default Home;